import React from "react"
import Card from "./card"
import * as styles from "./styles.module.css"
import { useScreenWidth } from "../../hooks/useScreenWidth"
import MobileSwiper from "./mobileSwiper"


const WhereWeOperate = ({ block }) => {
    const width = useScreenWidth()
    return (
        <section className="where-we-operate">
            <div className="container-fluid pb-120 pb-60-tablet">
                <hr className="section-divider"></hr>
                <div className="row mt-12">
                    <div className="col-md-12">
                        <h3 className={`section-header ${styles.headline}`}>{block.headline}</h3>
                    </div>
                </div>
                <div className="row">
                {width < 768 ? 
                    <MobileSwiper block={block} />
                :
                    <>{block.location.map((card, index) =>
                            <Card key={index} data={card}/>
                    )}</>
                }
                    
                </div>
            </div>
        </section>
    )
}

export default WhereWeOperate