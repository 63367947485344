// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var colors = "\"../../assets/css/_colors.css\"";
export var deepOrange = "#FF8A0F";
export var deepYellow = "#FFCC23";
export var description = "styles-module--description--2481d";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var hovering = "styles-module--hovering--8da67";
export var image__container = "styles-module--image__container--59750";
export var lightBg = "#FFF8E8";
export var lightOrange = "#FFDCB7";
export var lightPurple = "#E1DBFF";
export var lightYellowBg = "#FFF0BD";
export var lightestPurple = "#E2DDFF";
export var mediumOrange = "#FFB96F";
export var number = "styles-module--number--94ed9";
export var open = "styles-module--open--84e70";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var section_header = "styles-module--section_header--248ef";
export var show = "styles-module--show--5fe17";
export var step = "styles-module--step--35574";
export var stepContainer = "styles-module--stepContainer--4e2ae";
export var step__container = "styles-module--step__container--de9ba";
export var step__image = "styles-module--step__image--bc852";
export var text = "styles-module--text--44ab2";
export var yellowBtn = "#FFE07B";