import React, { useRef, useEffect, useState } from "react"
import Button from "../button"
import * as styles from "./styles.module.css"
import useScrollPosition from "../../hooks/useScrollPosition";

const Mission = ({ block }) => {
    const scrollPosition = useScrollPosition()
    const ref = useRef()
    const [startAnimation, setStartAnimation] = useState(false)
    const isInViewport = function (elem) {
        var distance = elem.current.getBoundingClientRect()
        return (
            distance.top >= 0 &&
            distance.left >= 0 &&
            distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            distance.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
    }
    useEffect(() => {
        if(isInViewport(ref)) {
            setStartAnimation(true)
        }
    }, [scrollPosition])
    
    return (
        <section className={styles.mission}>
            <div className="container-fluid">
                <hr className="section-divider"></hr>
                <div className="row mt-12">
                    <div className="col-lg-12">
                        <div className="flex space-between align-start">
                            <h3 className={`section-header ${styles.small_headline}`}>{block.smallHeadline}</h3>
                            {block.button.map((button, index) =>
                                <Button
                                    key={index}
                                    type="purpleMarchingAnts"
                                    text={button.buttonText}
                                    url={button.buttonUrl}
                                    newTab={button.newTab}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${styles.text_row} row pb-120 pb-60-tablet wrap-reverse`}>
                    <div className={`col-lg-7 col-xl-6 offset-xl-3 ${styles.text_block}`}>
                        <h2 className={`${styles.headline} text-left`}>{block.headline}</h2>
                        <p className={`${styles.description} text-left`}>{block.description}</p>
                    </div>
                    <div ref={ref} className="col-lg-5 col-xl-3">
                        <div className={`${startAnimation ? styles.animate : ""} ${styles.butterfly_container}`}>
                            <div className={`${styles.shape} ${styles.shape_1}`}></div>
                            <div className={`${styles.shape} ${styles.shape_2}`}></div>
                            <div className={`${styles.shape} ${styles.shape_3}`}></div>
                            <div className={`${styles.shape} ${styles.shape_4}`}></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Mission