// extracted by mini-css-extract-plugin
export var animate = "styles-module--animate--3d0f3";
export var blackish = "#121117";
export var butterfly_container = "styles-module--butterfly_container--f3c94";
export var colors = "\"../../assets/css/_colors.css\"";
export var deepOrange = "#FF8A0F";
export var deepYellow = "#FFCC23";
export var description = "styles-module--description--49615";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var headline = "styles-module--headline--6d9f2";
export var lightBg = "#FFF8E8";
export var lightOrange = "#FFDCB7";
export var lightYellowBg = "#FFF0BD";
export var lightestPurple = "#E2DDFF";
export var mediumOrange = "#FFB96F";
export var mission = "styles-module--mission--d8a37";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var shape = "styles-module--shape--0ef9d";
export var shape1Animation = "styles-module--shape-1-animation--ae631";
export var shape2Animation = "styles-module--shape-2-animation--e49b9";
export var shape3Animation = "styles-module--shape-3-animation--71971";
export var shape4Animation = "styles-module--shape-4-animation--c9134";
export var shape_1 = "styles-module--shape_1--fea6c";
export var shape_2 = "styles-module--shape_2--61e0f";
export var shape_3 = "styles-module--shape_3--2f8cb";
export var shape_4 = "styles-module--shape_4--7f3c3";
export var small_headline = "styles-module--small_headline--03145";
export var text_block = "styles-module--text_block--7c648";
export var text_row = "styles-module--text_row--72dbf";
export var yellowBtn = "#FFE07B";