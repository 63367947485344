import React, { useState } from "react"
import * as styles from "./styles.module.css"

const Row = ({ data }) => {
    const [showDescription, setShowDescription] = useState(false)
    const handleClick = () => {
        setShowDescription(!showDescription)
    }
    return (
        <div onClick={handleClick} 
            className={`${styles.row} row ${showDescription ? styles.show_description : ""}`}>
            <div className={`col-lg-5 col-8 ${styles.headline}`}>
                <h3>{data.headline}</h3>
            </div>
            <div className={`col-lg-3 col-4 ${styles.icon}`}>
                <video width="112" height="112" autoplay="autoplay" muted="muted" loop="loop" playsInline={true}>
                    <source src={data.icon.url} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
            <p className={`col-lg-4 ${styles.description}`}>{data.description}</p>
        </div>
    )
}

export default Row