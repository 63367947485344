import React, { useState } from "react"
import Button from "../button"
import Step from "./step"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./styles.module.css"

const GettingStarted = ({ block }) => {
    const [active, setActive] = useState(0)
    const handleClick = (index) => {
        setActive(index)
    }
    return (
        <section>
            <div className="container-fluid pb-120 pb-60-tablet">
                <hr className="section-divider"></hr>
                <div className="row mt-12 pb-36-tablet">
                    <div className="col-md-12">
                        <div className="flex space-between align-start">
                            <h3 className={`${styles.section_header} section-header`}>{block.headline}</h3>
                            {block.button.map((button, index) =>
                                <Button
                                    key={index}
                                    type="purpleMarchingAnts"
                                    text={button.buttonText}
                                    url={button.buttonUrl}
                                    newTab={button.newTab}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="row mt-60 mt-0-tablet">
                    <div className="col-md-7">
                        {block.steps.map((step, index) => 
                            <div
                                className={styles.step__container} 
                                key={index} 
                                onClick={() => {
                                    handleClick(index)
                                }}>
                                <Step 
                                    number={index} 
                                    data={step}
                                    expanded={active === index ? true : false}
                                />
                            </div>
                        )}
                    </div>
                    <div className="col-md-5">
                        <div className={styles.image__container}>
                            {block.steps.map((step, index) => 
                                <GatsbyImage 
                                    objectPosition={`${step.image.focalPoint.x*100}% ${step.image.focalPoint.y*100}%`}
                                    key={index} 
                                    className={`${active === index ? styles.show : ""} ${styles.step__image}`} 
                                    image={step.image.data}
                                    alt={step.image.alt}
                                    imgStyle={{
                                        objectPosition: `${step.image.focalPoint.x*100}% ${step.image.focalPoint.y*100}%`
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GettingStarted