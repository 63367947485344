// extracted by mini-css-extract-plugin
export var animatedShape1 = "cta-module--animatedShape1--e2a04";
export var animatedShape2 = "cta-module--animatedShape2--cdf92";
export var animatedShape3 = "cta-module--animatedShape3--63ed6";
export var animatedShape4 = "cta-module--animatedShape4--d0c04";
export var animatedShape5 = "cta-module--animatedShape5--a0d33";
export var animatedShape6 = "cta-module--animatedShape6--8f7ee";
export var animatedShape7 = "cta-module--animatedShape7--33081";
export var animatedShape8 = "cta-module--animatedShape8--54f08";
export var animatedShape9 = "cta-module--animatedShape9--fb507";
export var bg__orange = "cta-module--bg__orange--b948c";
export var bg__purple = "cta-module--bg__purple--8db49";
export var bg__transparent = "cta-module--bg__transparent--adb66";
export var bg__yellow = "cta-module--bg__yellow--a8df8";
export var blackish = "#121117";
export var circle = "cta-module--circle--76e39";
export var colors = "\"../../assets/css/_colors.css\"";
export var cta = "cta-module--cta--b28c4";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var inner = "cta-module--inner--98d8d";
export var inner__container = "cta-module--inner__container--08b86";
export var lightBg = "#FFF8E8";
export var mediumOrange = "#FFB96F";
export var no_border_bottom = "cta-module--no_border_bottom--e201e";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var right__angle = "cta-module--right__angle--32b9e";
export var rotate_180 = "cta-module--rotate_180--6696a";
export var rotate_270 = "cta-module--rotate_270--e37f2";
export var rotate_90 = "cta-module--rotate_90--bab86";
export var row_1 = "cta-module--row_1--8f402";
export var row_1_mobile = "cta-module--row_1_mobile--7a7f6";
export var row_2 = "cta-module--row_2--72bae";
export var row_3 = "cta-module--row_3--94507";
export var row_4 = "cta-module--row_4--e9445";
export var row_4_mobile = "cta-module--row_4_mobile--93d53";
export var shape = "cta-module--shape--4ca08";
export var state_1 = "cta-module--state_1--398e5";
export var state_2 = "cta-module--state_2--6a3be";
export var state_3 = "cta-module--state_3--e0622";
export var transparent__square = "cta-module--transparent__square--9a1b9";
export var wedge = "cta-module--wedge--a46f3";
export var yellowBtn = "#FFE07B";