import React from "react"
import Button from "../button"
import Card from "./card"
import * as styles from "./styles.module.css"
import { useScreenWidth } from "../../hooks/useScreenWidth"
import MobileSwiper from "./mobileSwiper"

const HowCanWeHelp = ({ block }) => {
    const cardColumnWidth = 12 / block.cards.length
    return (
        <section className="how-we-can-help">
            <div className={`${styles.container} container`}>
                <hr className="section-divider"></hr>
                <div className="row mt-60 mt-16-tablet">
                    <div className="col-md-12">
                        <div className={`flex space-between align-start ${styles.header}`}>
                            <h3 className="section-header">{block.headline}</h3>
                            {block.button.map((button, index) =>
                                <Button
                                    key={index}
                                    type="purpleMarchingAnts"
                                    text={button.buttonText}
                                    url={button.buttonUrl}
                                    newTab={button.newTab}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ paddingLeft: "12px" }} className={`${styles.card__row} ${styles.mobile_only} row`}>
                    <MobileSwiper block={block}/>
                </div>
                <div className={`${styles.card__row} row ${styles.desktop_only}`}>
                    {block.cards.map((card, index) =>
                        <div key={index} className={`col-md-${cardColumnWidth} ${styles.card_col}`}>
                            <Card headline={card.headline} body={card.description} image={card.image} specialtiesLink={block.specialtiesLink}/>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default HowCanWeHelp