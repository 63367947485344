import * as React from "react"
import { Seo } from "../components/seo"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HomepageCta from "../components/homepageCta"
import Faqs from "../components/faqs"
import HomepageBanner from "../components/homepageBanner"
import HowCanWeHelp from "../components/howCanWeHelp"
import Mission from "../components/mission"
import WholemindMethod from "../components/wholemindMethod"
import WhereWeOperate from "../components/whereWeOperate"
import GettingStarted from "../components/gettingStarted"
import OurTeam from "../components/ourTeam"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      {data.page.pageBuilder.map((block, index) =>
        JSON.stringify(block).length > 2 ?
          block.model.apiKey === "homepage_banner" ? 
            <HomepageBanner 
              key={index}
              headline={block.headline}
              banners={block.banners}
            />
          :
          block.model.apiKey === "cta" ?
            <HomepageCta
              key={index}
              headline={block.headline}
              buttonText={block.buttonText}
              buttonLink={block.buttonUrl}
              newTab={block.newTab}
            />
          : block.model.apiKey === "faq_section" ? 
            <section>
              <div key={index} className="container-fluid pb-120 pb-60-tablet">
                <hr className="section-divider"></hr>
                <div className="row mt-12">
                  <div className="col-md-12">
                    <Faqs headline={block.headline} faqs={block.faqs}/>
                  </div>
                </div>
              </div>
            </section>
          : block.model.apiKey === "how_can_we_help" ? 
              <HowCanWeHelp key={index} block={block}/>
          : block.model.apiKey === "mission" ? 
              <Mission key={index} block={block}/>
          : block.model.apiKey === "wholemind_method" ? 
              <WholemindMethod key={index} block={block}/>
          : block.model.apiKey === "where_we_operate" ? 
              <WhereWeOperate key={index} block={block}/>
          : block.model.apiKey === "getting_started" ? 
              <GettingStarted key={index} block={block}/>
          : block.model.apiKey === "our_team" ? 
              <OurTeam key={index} block={block}/>
          : <div key={index}></div>
        : <div key={index}></div>
      )}
    </Layout> 
  )
}

// get content from Dato CMS

export const data = graphql`
  query FaqQuery {
    page: datoCmsHomepage {
      pageBuilder {
        ... on DatoCmsCta {
          headline
          buttonText
          buttonUrl
          newTab
          model {
            apiKey
          }
        }
        ... on DatoCmsFaqSection {
          model {
            apiKey
          }
          headline
          faqs {
            question
            answer {
              text
            }
          }
        }
        ... on DatoCmsHomepageBanner {
          model {
            apiKey
          }
          headline
          banners {
            image {
              data: gatsbyImageData (
                layout: FULL_WIDTH
              )
              focalPoint {
                x
                y
              }
              url
              alt
            }
            callOuts {
              text
            }
          }
        }
        ... on DatoCmsHowCanWeHelp {
          id
          model {
            apiKey
          }
          headline
          button {
            buttonText
            buttonUrl
            newTab
          }
          specialtiesLink
          cards {
            headline
            description
            image {
              data: gatsbyImageData (
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
              url
              alt
              focalPoint {
                x
                y
              }
            }
          }
        }
        ... on DatoCmsMission {
          id
          model {
            apiKey
          }
          smallHeadline
          button {
            buttonText
            buttonUrl
            newTab
          }
          headline
          description
        }
        ... on DatoCmsWholemindMethod {
          id
          model {
            apiKey
          }
          headline
          row {
            headline
            icon {
              url
            }
            description
          }
        }
        ... on DatoCmsWhereWeOperate {
          id
          model {
            apiKey
          }
          headline
          location {
            headline
            description
            image {
              url
              alt 
              focalPoint {
                x
                y
              }
              data: gatsbyImageData (
                width: 440,
                height: 440,
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
            requestAnAppointmentUrl
          }
        }
        ... on DatoCmsGettingStarted {
          id
          model {
            apiKey
          }
          headline
          button {
            buttonText
            buttonUrl
            newTab
          }
          steps {
            headline
            description
            image {
              data: gatsbyImageData (
                width: 325,
                height: 556,
                layout: FULL_WIDTH
              )
              focalPoint {
                x
                y
              }
              url
              alt
            }
          }
        }
        ... on DatoCmsOurTeam {
          id
          model {
            apiKey
          }
          smallHeadline
          button {
            buttonText
            buttonUrl
            newTab
          }
          headline
          bodyText
          marqueeImages {
            data: gatsbyImageData (
              width: 300,
              height: 300,
              layout: FULL_WIDTH
            )
            url
            alt
            focalPoint {
              x
              y
            }
          }
        }
      }
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`

export const Head = ({ data }) => {
  return (
    <Seo 
      title={data.page.seo.title}
      description={data.page.seo.description}
      image={data.page.seo.image}
    />
  )
}

export default IndexPage