import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "../../card/styles.module.css"

const MobileCard = ({ data }) => {
    return (
        <div className={`${styles.card} card col-md-4`}>
            <div className={styles.topBorderDashed}>
                <h4 className={styles.headline}>{data.headline}</h4>
                <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
            </div>
            <a
                target="_blank"
                rel="noreferrer"
                href={data.requestAnAppointmentUrl}
                className={`${styles.locationCard}`}>
                <div className={`${styles.imageContainer} image-container`}>
                    <div className={`hover-shape ${styles.hoverShape}`}></div>
                    <GatsbyImage 
                        objectPosition={`${data.image.focalPoint.x*100}% ${data.image.focalPoint.y*100}%`}
                        className={styles.gatsbyImage} 
                        alt={data.image.alt} 
                        image={data.image.data} 
                    />
                </div>
            </a>
        </div>
    )
}

export default MobileCard