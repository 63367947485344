import React, { useEffect, useRef, useState, useCallback } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./styles.module.css"

const Card = ({ data }) => {
    const [hovering, setHovering] = useState(false)
    const handleMouseEnter = () => {
        setHovering(true)
    }
    const handleMouseLeave = () => {
        setHovering(false)
    }

    // Follow cursor animation -- https://codepen.io/StephenScaff/pen/Jjdveyw?editors=0110
    const cursorRef = useRef()
    const cursorContainerRef = useRef()
    const [doc, setDoc] = useState()

    const [isVisible, setIsVisible] = useState(true)

    let endX = useRef(0)
    let endY = useRef(0)

    const onMouseEnter = useCallback(() => setIsVisible(true), [])
    const onMouseLeave = useCallback(() => setIsVisible(false), [])

    useEffect(() => {
        setDoc(cursorContainerRef.current)
    }, [])

    useEffect(() => {
        if (isVisible) {
            cursorRef.current.style.position = 'fixed'
            cursorRef.current.style.display = 'block'
        } else {
            cursorRef.current.style.display = 'none'
        }
    }, [isVisible])


    function useEventListener(eventName, handler, element = doc) {
        const savedHandler = useRef()

        useEffect(() => {
            savedHandler.current = handler
        }, [handler])

        useEffect(() => {
            const isSupported = element && element.addEventListener
            if (!isSupported) return

            const eventListener = (event) => savedHandler.current(event)

            element.addEventListener(eventName, eventListener)

            return () => {
                element.removeEventListener(eventName, eventListener)
            }
        }, [eventName, element])
    }

    const onMouseMove = useCallback(({ clientX, clientY }) => {
        cursorRef.current.style.top = clientY + 'px'
        cursorRef.current.style.left = clientX + 'px'
        endX.current = clientX
        endY.current = clientY
    }, [])

    useEventListener('mousemove', onMouseMove, doc)
    useEventListener('mouseenter', onMouseEnter, doc)
    useEventListener('mouseleave', onMouseLeave, doc)

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave} 
            className={`${styles.card} col-md-4`}>
            <div className={`${hovering ? styles.topBorderSolid : styles.topBorderDashed} ${styles.cardBorder}`}>
                <h4 className={styles.headline}>{data.headline}</h4>
                <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
            </div>
            <a
                target="_blank"
                rel="noreferrer"
                href={data.requestAnAppointmentUrl}
                ref={cursorContainerRef}
                className={`${styles.locationCard} ${hovering ? styles.tallerCard : ""}`}>
                <div ref={cursorRef} className={`${styles.customCursor}`}>
                    <p>Request Appointment</p>
                </div>
                <div className={`${hovering ? styles.hovering : ""} ${styles.imageContainer}`}>
                    <div className={styles.hoverShape}></div>
                    <GatsbyImage 
                        objectPosition={`${data.image.focalPoint.x*100}% ${data.image.focalPoint.y*100}%`}
                        className={styles.gatsbyImage} 
                        alt={data.image.alt} 
                        image={data.image.data}    
                    />
                </div>
            </a>
        </div>
    )
}

export default Card