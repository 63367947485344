import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "./styles.css"
import MobileCard from "./mobileCard"

const MobileSwiper = ({ block }) => {
    return (
        <Swiper
            slidesPerView={"auto"}
            spaceBetween={12}
            loop={true}
        >
            {block.location.map((card, index) =>
                <SwiperSlide>
                    <MobileCard key={index} data={card}/>
                </SwiperSlide>
            )}
        </Swiper>
    )
}

export default MobileSwiper