import React, { useState } from "react"
import * as styles from "../styles.module.css"

const Step = ({ data, number, expanded }) => {
    const [hovering, setHovering] = useState(false)
    var listNum = number+1
    if(listNum < 10) {
        listNum = "0" + listNum
    }
    const handleMouseEnter = () => {
        setHovering(true)
    }
    const handleMouseLeave = () => {
        setHovering(false)
    }
    return (
        <button onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`${styles.stepContainer} ${expanded ? styles.open : ""} ${hovering ? styles.hovering : ""}`}>
            <div className={styles.step}>
                <div className={styles.number}>
                    <span>{listNum}</span>
                </div>
                <div className={styles.text}>
                    <h3>{data.headline}</h3>
                </div>
            </div>
            {expanded && <p className={`xl-body ${styles.description}`}>{data.description}</p>}
        </button>
    )
}

export default Step