// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var card = "styles-module--card--7ffb9";
export var card__row = "styles-module--card__row--6fab0";
export var card_col = "styles-module--card_col--f1269";
export var colors = "\"../../assets/css/_colors.css\"";
export var container = "styles-module--container--b4edf";
export var cursorContainer = "styles-module--cursorContainer--f56c5";
export var customCursor = "styles-module--customCursor--5632f";
export var desktop_only = "styles-module--desktop_only--ed057";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gatsbyImage = "styles-module--gatsbyImage--4000d";
export var gtWalsheim = "'gt-walsheim'";
export var header = "styles-module--header--c9645";
export var hovering = "styles-module--hovering--ba19d";
export var imageContainer = "styles-module--imageContainer--ab6c6";
export var lightBg = "#FFF8E8";
export var lightGreyBg = "#EAEAEA";
export var lightOrange = "#FFDCB7";
export var lightPurple = "#E1DBFF";
export var lightYellowBg = "#FFF0BD";
export var marchingAnts = "styles-module--marching-ants--7ca42";
export var mediumOrange = "#FFB96F";
export var mobile_only = "styles-module--mobile_only--f8592";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var textContainer = "styles-module--textContainer--f5e48";
export var yellowBtn = "#FFE07B";