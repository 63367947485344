import React from "react"
import Card from "../card"
import * as styles from "../styles.module.css"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "./styles.css"

const MobileSwiper = ({ block }) => {
    return (
        <Swiper
            slidesPerView={"auto"}
            spaceBetween={12}
            loop={true}
        >
            {block.cards.map((card, index) =>
                <SwiperSlide>
                    <div key={index} className={`card_col ${styles.card_col}`}>
                        <Card headline={card.headline} body={card.description} image={card.image} specialtiesLink={block.specialtiesLink}/>
                    </div>
                </SwiperSlide>
            )}
        </Swiper>
    )
}

export default MobileSwiper