import React from "react"
import * as styles from "./styles.module.css"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"
import "swiper/css"
import "swiper/css/effect-fade"
import "./styles.css"
// import swiper modules
import { EffectFade, Autoplay } from "swiper"

const HomepageBanner = ({ headline, banners }) => {
    return (
        <section>
            <div className={`banner ${styles.banner}`}>
                <Swiper
                    slidesPerView={1}
                    effect={"fade"}
                    spaceBetween={0}
                    speed={700}
                    allowTouchMove={false}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false
                    }}
                    modules={[EffectFade, Autoplay]}
                >
                    {banners.map((banner, index) => 
                        <SwiperSlide key={index}>
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className={`${styles.padding__right__6__lg} col-lg-6`}>
                                        <div className={`shape-bg ${styles.shape__bg}`}>
                                            <div className={styles.inner__text} dangerouslySetInnerHTML={{ __html: headline }}></div>
                                        </div>
                                    </div>
                                    <div className={`${styles.padding__left__6__lg} ${styles.light__bg} col-lg-6`}>
                                        <div className={`${styles.image__bg} image__bg`} >
                                            <GatsbyImage 
                                                className={styles.banner_image} 
                                                image={banner.image.data} 
                                                alt={banner.image.alt}
                                                objectPosition={`${banner.image.focalPoint.x*100}% ${banner.image.focalPoint.y*100}%`} />
                                            {banner.callOuts.map((callout, index) => 
                                                <div key={index} className={`${styles.callOut} call_out`}>
                                                    <p>{callout.text}</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </section>
    )
}

export default HomepageBanner