import { useEffect, useState } from "react"

export const useScreenWidth = () => {
  const [width, setWidth] = useState(null)

  // Listen to resize
  useEffect(() => {
    setWidth(window.innerWidth)
    const resizeListener = window.addEventListener("resize", () => {
      setWidth(window.innerWidth)
    })

    return () => window.removeEventListener("resize", resizeListener)
  }, [])

  return width
}