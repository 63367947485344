import React, { useEffect, useState } from "react";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import Button from "../button";
import * as styles from "./cta.module.css"

const HomepageCta = ({ headline, buttonText, buttonLink, newTab }) => {
    const [state, setState] = useState(1)

    const width = useScreenWidth()

    useEffect(() => {
        const interval = setInterval(() => {
            setState((prevState) => prevState+1)
        }, 3000)
        return () => clearInterval(interval)
    }, [])

    return (
        <section>
            <div className={`${styles.cta} ${width >= 768 ? (state%3 === 1 ? styles.state_1 : state%3 === 2 ? styles.state_2 : styles.state_3) : ""}`}>
                {width < 768 ? 
                    <>
                        <div className={styles.row_1_mobile}>
                            <div className={`${styles.shape} ${styles.wedge} ${styles.bg__transparent} ${styles.rotate_90}`}></div>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__transparent}`}></div>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__orange}`}></div>
                            <div className={`${styles.right__angle} ${styles.shape} ${styles.bg__transparent} ${styles.rotate_270}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                        </div>
                        <div className={styles.row_4_mobile}>
                            <div className={`${styles.right__angle} ${styles.shape} ${styles.rotate_180} ${styles.bg__transparent}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                            <div className={`${styles.shape} ${styles.wedge} ${styles.rotate_270} ${styles.bg__yellow}`}></div>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__transparent}`}></div>
                            <div className={`${styles.right__angle} ${styles.shape} ${styles.bg__purple}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                        </div>
                    </>
                : 
                    <>
                        <div className={styles.row_1}>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__transparent}`}></div>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__orange} ${styles.animatedShape1}`}></div>
                            <div className={`${styles.right__angle} ${styles.shape} ${styles.bg__transparent} ${styles.rotate_270} ${styles.no_border_bottom} ${styles.animatedShape2}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                            <div className={`${styles.right__angle} ${styles.shape} ${styles.bg__transparent}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__transparent} ${styles.animatedShape3}`}></div>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__transparent}`}></div>
                            <div className={`${styles.right__angle} ${styles.shape} ${styles.bg__transparent} ${styles.rotate_270} ${styles.no_border_bottom}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                            <div className={`${styles.right__angle} ${styles.shape} ${styles.bg__transparent} ${styles.no_border_bottom}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                        </div>
                        <div className={styles.row_2}>
                            <div className={`${styles.shape} ${styles.wedge} ${styles.bg__transparent} ${styles.rotate_90}`}></div>
                            <div className={`${styles.shape} ${styles.wedge} ${styles.bg__transparent} ${styles.rotate_180} ${styles.animatedShape4}`}></div>
                        </div>
                        <div className={styles.row_3}>
                            <div className={`${styles.shape} ${styles.wedge} ${styles.bg__transparent} ${styles.animatedShape5}`}></div>
                            <div className={`${styles.shape} ${styles.wedge} ${styles.rotate_270} ${styles.animatedShape6}`}></div>
                        </div>
                        <div className={styles.row_4}>
                            <div className={`${styles.animatedShape7} ${styles.right__angle} ${styles.shape} ${styles.rotate_180}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                            <div className={`${styles.animatedShape8} ${styles.right__angle} ${styles.shape} ${styles.bg__transparent} ${styles.rotate_90}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__transparent}`}></div>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__transparent}`}></div>
                            <div className={`${styles.right__angle} ${styles.shape} ${styles.bg__transparent} ${styles.rotate_180}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                            <div className={`${styles.right__angle} ${styles.shape} ${styles.bg__transparent} ${styles.rotate_90}`}>
                                <div className={styles.transparent__square}></div>
                            </div>
                            <div className={`${styles.animatedShape9} ${styles.shape} ${styles.circle} ${styles.bg__transparent}`}></div>
                            <div className={`${styles.shape} ${styles.circle} ${styles.bg__transparent}`}></div>
                        </div>
                    </>
                }
                <div className={`container ${styles.inner__container}`}>
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3">
                            <div className={styles.inner}>
                                <h2>{headline}</h2>
                                <Button 
                                    type="jumboPurple" 
                                    url={buttonLink} 
                                    text={buttonText}
                                    newTab={newTab}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomepageCta