import React, { useEffect, useRef, useState, useCallback } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "../styles.module.css"

const Card = ({ headline, body, image, specialtiesLink }) => {
    const [hovering, setHovering] = useState(false)
    const handleMouseEnter = () => {
        setHovering(true)
    }
    const handleMouseLeave = () => {
        setHovering(false)
    }

    //Follow cursor animation -- https://codepen.io/StephenScaff/pen/Jjdveyw?editors=0110
    const cursorRef = useRef()
    const cursorContainerRef = useRef()
    const [doc, setDoc] = useState()

    const [isVisible, setIsVisible] = useState(true)

    let endX = useRef(0)
    let endY = useRef(0)

    const onMouseEnter = useCallback(() => setIsVisible(true), [])
    const onMouseLeave = useCallback(() => setIsVisible(false), [])

    useEffect(() => {
        setDoc(cursorContainerRef.current)
    }, [])

    useEffect(() => {
        if (isVisible) {
            cursorRef.current.style.position = 'fixed'
            cursorRef.current.style.display = 'block'
        } else {
            cursorRef.current.style.display = 'none'
        }
    }, [isVisible])


    function useEventListener(eventName, handler, element = doc) {
        const savedHandler = useRef()

        useEffect(() => {
            savedHandler.current = handler
        }, [handler])

        useEffect(() => {
            const isSupported = element && element.addEventListener
            if (!isSupported) return

            const eventListener = (event) => savedHandler.current(event)

            element.addEventListener(eventName, eventListener)

            return () => {
                element.removeEventListener(eventName, eventListener)
            }
        }, [eventName, element])
    }

    const onMouseMove = useCallback(({ clientX, clientY }) => {
        cursorRef.current.style.top = clientY + 'px'
        cursorRef.current.style.left = clientX + 'px'
        endX.current = clientX
        endY.current = clientY
    }, [])

    useEventListener('mousemove', onMouseMove, doc)
    useEventListener('mouseenter', onMouseEnter, doc)
    useEventListener('mouseleave', onMouseLeave, doc)

    return (
        <a href={`${specialtiesLink}`} className={styles.cursorContainer} ref={cursorContainerRef}>
            <div ref={cursorRef} className={`${styles.customCursor}`}>
                <p>See Specialties</p>
            </div>
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={`${hovering ? styles.hovering : "" } ${styles.card} card`}>
                <div className={styles.textContainer}>
                    <h3>{headline}</h3>
                    <p>{body}</p>
                </div>
                <div className={styles.imageContainer}>
                    <GatsbyImage
                        objectPosition={`${image.focalPoint.x*100}% ${image.focalPoint.y*100}%`} 
                        className={styles.gatsbyImage} 
                        image={image.data} 
                        alt={image.alt ? image.alt : ""} />
                </div>
            </div>
        </a>
    )
}

export default Card