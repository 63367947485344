// extracted by mini-css-extract-plugin
export var banner = "styles-module--banner--41c9a";
export var banner_image = "styles-module--banner_image--3a591";
export var blackish = "#121117";
export var callOut = "styles-module--callOut--f92d4";
export var colors = "\"../../assets/css/_colors.css\"";
export var fonts = "\"../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var image__bg = "styles-module--image__bg--540d9";
export var inner__text = "styles-module--inner__text--59cee";
export var lightBg = "#FFF8E8";
export var lightOrange = "#FFDCB7";
export var lightPurple = "#E1DBFF";
export var lightYellowBg = "#FFF0BD";
export var light__bg = "styles-module--light__bg--88411";
export var mediumOrange = "#FFB96F";
export var padding__left__6__lg = "styles-module--padding__left__6__lg--18417";
export var padding__right__6__lg = "styles-module--padding__right__6__lg--9fa80";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var shape__bg = "styles-module--shape__bg--18483";
export var yellowBtn = "#FFE07B";