// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var card = "styles-module--card--868fe";
export var cardBorder = "styles-module--cardBorder--91ecb";
export var colors = "\"../../../assets/css/_colors.css\"";
export var customCursor = "styles-module--customCursor--6d7d0";
export var fonts = "\"../../../assets/css/_fonts.css\"";
export var gatsbyImage = "styles-module--gatsbyImage--4926a";
export var gtWalsheim = "'gt-walsheim'";
export var headline = "styles-module--headline--2c4f7";
export var hoverShape = "styles-module--hoverShape--577cf";
export var hovering = "styles-module--hovering--194ae";
export var imageContainer = "styles-module--imageContainer--ce338";
export var lightBg = "#FFF8E8";
export var lightGreyBg = "#EAEAEA";
export var lightOrange = "#FFDCB7";
export var lightPurple = "#E1DBFF";
export var lightYellowBg = "#FFF0BD";
export var locationCard = "styles-module--locationCard--b39b9";
export var mediumOrange = "#FFB96F";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var tallerCard = "styles-module--tallerCard--b4e64";
export var topBorderDashed = "styles-module--topBorderDashed--00d0d";
export var topBorderSolid = "styles-module--topBorderSolid--a6acb";
export var yellowBtn = "#FFE07B";