import React from "react"
import Button from "../button"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"
import "swiper/css"
import "swiper/css/free-mode"
import "./styles.css"
// import swiper modules
import { FreeMode, Autoplay } from "swiper"

const OurTeam = ({ block }) => {
    return (
        <section className="our-team">
            <div className="pb-120 pb-60-tablet">
                <div className="container-fluid">
                    <hr className="section-divider"/>
                    <div className="row mt-12">
                        <div className="col-md-12">
                            <div className="flex space-between align-start">
                                <h3 className="section-header">{block.smallHeadline}</h3>
                                {block.button.map((button, index) =>
                                    <Button
                                        key={index}
                                        type="purpleMarchingAnts"
                                        text={button.buttonText}
                                        url={button.buttonUrl}
                                        newTab={button.newTab}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row pb-60">
                        <div className="col-md-7">
                            <h2 className="text-left">{block.headline}</h2>
                            <p>{block.bodyText}</p>
                        </div>
                    </div>
                </div>
                <Swiper
                    spaceBetween={12}
                    loop={true}
                    freeMode={true}
                    slidesPerView={"auto"}
                    speed={block.marqueeImages.length * 2500}
                    freeModeMomentum={false}
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                    }}
                    modules={[FreeMode, Autoplay]}
                    >
                    {block.marqueeImages.map((image, index) =>
                        <>
                            <SwiperSlide>
                                <div className="image-container">
                                    <GatsbyImage
                                        objectPosition={`${image.focalPoint.x*100}% ${image.focalPoint.y*100}%`}
                                        imgClassName="marquee-image" 
                                        image={image.data} 
                                        alt={image.alt} 
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="marquee-shape"></div>
                            </SwiperSlide>
                        </>
                    )}
                </Swiper>
            </div>
        </section>
    )
}

export default OurTeam