import React from "react"
import Row from "./row"
import * as styles from "./styles.module.css"

const WholemindMethod = ({ block }) => {
    return (
        <section>
            <div className="container-fluid">
                <hr className="section-divider"></hr>
                <div className="row mt-12">
                    <div className="col-lg-12">
                        <h3 className={`section-header ${styles.colorShift}`}>{block.headline}</h3>
                    </div>
                </div>
                <div className="row pb-40 pt-50 pt-24-tablet">
                    <div className="offset-lg-3 col-lg-9">
                        {block.row.map((row, index) =>
                            <div key={index}>
                                <Row key={index} data={row}/>
                                {index+1 !== block.row.length ? 
                                    <hr className={styles.dottedHr}></hr> : ""
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WholemindMethod